import React, { lazy } from "react"
import { offeringCards } from "./helper"
import "./shape-offering.scss"
import getOfferingImages from "./components/getOfferingsImages"
import Measurement from "../../images/home/offering/measurement.svg"
import Automation from "../../images/home/offering/automation.jpg" // .svg
import Yn from "../../images/home/offering/yn.svg"
import Reports from "../../images/home/offering/reports2.svg"
import Proscore from "../../images/home/offering/proscore.svg"
import Healthage from "../../images/home/offering/healthage.svg"
import Strength from "../../images/home/offering/strength.svg"
import Eap from "../../images/home/offering/eap.svg"
import Analytics from "../../images/home/offering/analytics.svg"
import Advantage from "../../images/home/offering/advantage.svg"
import Hybrid from "../../images/home/offering/hybrid.png"
import Bestpractices from "../../images/home/offering/bestpractices.svg"
import Shapekit from "../../images/home/offering/shapekit.svg"
import Heatmap from "../../images/home/offering/heatmap.svg"
import Pulse from "../../images/home/offering/pulse.svg"
import OfferingCarousel from "./components/offeringCarousel"
import Slider from "react-slick"

const ShapeOffering = () => {
  const ImagesMap = {
    measurement: Measurement,
    automation: Automation,
    yn: Yn,
    reports: Reports,
    proscore: Proscore,
    healthage: Healthage,
    strength: Strength,
    eap: Eap,
    analytics: Analytics,
    advantage: Advantage,
    hybrid: Hybrid,
    bestpractices: Bestpractices,
    shapekit: Shapekit,
    heatmap: Heatmap,
    pulse: Pulse,
  }
  let isDesktop =
    typeof window !== `undefined` &&
    !window.matchMedia("(max-width: 1600px)").matches
  let isMobile =
    typeof window !== `undefined` &&
    window.matchMedia("(max-width: 786px)").matches
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  let cardsElem = () =>
    offeringCards?.map((card, index) => (
      <div className="offeringCard" key={`${card.heading}-${index}`}>
        <div className="offeringImgWrapper">
          <img
            loading="lazy"
            src={ImagesMap[card.img]}
            alt={`offering-${card.img}`}
          />
        </div>
        <div className="offeringFadeGradient">
          <h4>{card.heading}</h4>
          <p>{card.desc}</p>
        </div>
      </div>
    ))
  return (
    <div className="offeringSection">
      <h2 className="offeringHeading">The SHAPE Toolkit</h2>
      <p className="offeringDesc">
        The most powerful features to empower faster flourishing.
      </p>
      {!isMobile ? (
        <div className="offeringCardsFlexWrapper">
          <div className="offeringCardsFlex">{cardsElem()}</div>
        </div>
      ) : (
        // <div>
        //   <Slider {...settings}>{cardsElem()}</Slider>
        // </div>
        <div className="offeringCardsFlexWrapper">
          <div className="offeringCardsFlex">{cardsElem()}</div>
        </div>
      )}
    </div>
  )
}
export default ShapeOffering
